export default {
  methods: {
//   getConsoleArtNo(): string {
//     let color = '90';
//     if      (this.selColor === AccessoryColor.Black)                color = '20';
//     else if (this.selColor === AccessoryColor.BrickRed)             color = '24';
//     else if (this.selColor === AccessoryColor.GalvanizedGreySilver) color = '90';
// 
//     switch (this.roof.selection.tileFamily.family) {
//       case TileFamily.Palema:
//       case TileFamily.Exklusiv:
//       case TileFamily.HansaFalsatLertegel:
//       case TileFamily.HansaFalsatLertegelRakFramkant:
//       case TileFamily.TvillingLertegelRakFramkant:
//       case TileFamily.TvillingFalsatLertegel:
//         if (this.roof.underlagstak === Underlagstak.RåspontMedPapp)
//           return '1348' + color;
//         else
//           return '1376' + color;
//       case TileFamily.Carisma:
//       case TileFamily.PianoFalsatLertegel:
//         return 'C1348' + color;
//         // Borde bara användas för råspont men carisma och piano borde inte vara valbara alls för andra underlagstak
//       case TileFamily.Strängpressat2kupLertegel:
//       // case TileFamily.Sandby:
//         return 'L1348' + color;
//         // Borde bara användas för råspont men höganäs borde inte vara valbar för andra underlagstak
//     }
//     return '';
//   },
    // PW uses the same console for everything, but the console to use depends on the tile and base roof
    getPWConsoleProduct() {
      let consoleName = '';
      switch (this.selections.rooftile.value) {
      case 'Palema 2-kupig':
      case 'Exklusiv 1-kupig':
      case 'Hansa 1-kupig':
      case 'Tvilling 2-kupig':
          if (this.selections.roofBase.value == 'Råspont med papp') consoleName = 'footplatHansa';
          else consoleName = 'fastener2Kup';
          break;
      case 'Carisma platt':
      case 'Piano platt':
          consoleName = 'footplatCarisma';
          break;
      case 'Höganäs 2-kupig':
          consoleName = 'footplatHöganäs';
          break;
      }

      let product = this.products.find(p => p.type === consoleName);
      let variant = product.variants.find(v => v.color == this.selections.accessoryColor.value);
      const fallbacks = { Galvad: 'Svart', Svart: 'Galvad', Tegelröd: 'Svart' };
      if (!variant) variant = product.variants.find(v => v.color == fallbacks[this.selections.accessoryColor.value]);

      let fullProduct = { ...product, ...variant };
      delete fullProduct.variants;
      fullProduct.sort = 60;
      fullProduct.unit = 'pkt';

      // Spread fullProduct into new object to avoid bugs with shared references to the same product
      return { ...fullProduct };
    },
  }
};
